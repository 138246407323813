import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { navigate } from '@reach/router'
import { Button, Form, Grid, Text, margin, padding, position, width } from 'chassis-react'

import api from '../../../api'

import formatTitles from '../../../utilities/formatTitles'
import toTitleCase from '../../../utilities/toTitleCase'
import valueFormat from '../../../utilities/valueFormat'

import adminUserService from '../../../services/adminUserService'

import stepperSteps from '../../../config/stepperSteps'

import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import FormDivider from '../../../components/FormDivider'
import FormRadioGroup from '../../../components/FormRadioGroup'
import FormSelectInput from '../../../components/FormSelectInput'
import FormTextInput from '../../../components/FormTextInput'
import FormWrapper from '../../../components/FormWrapper'
import Layout from '../../../components/Layout'
import Stepper from '../../../components/Stepper'

import { generatePersonalDetailsSchema } from './schema'
import getErrorForField from '../../../utilities/getErrorForField'
import focusOnError from '../../../utilities/focusOnError'
import handleBlur from '../../../utilities/handleBlur'
import { UserTypes } from '../../../config/UserTypes'

const populateUserData = data => ({
  TitleId: data.TitleId,
  Firstname: data.Firstname,
  ReferralCode: data.ReferralCode,
  Surname: data.Surname,
  DateOfBirth: data.DateOfBirth ? moment.utc(data.DateOfBirth).format('YYYY-MM-DD') : null,
  NINumber: data.NINumber,
  PassportExpiry: data.PassportExpiry ? moment.utc(data.PassportExpiry).format('YYYY-MM-DD') : null,
  Line1: data.Addresses ? data.Addresses[0].Line1 : '',
  Line2: data.Addresses ? data.Addresses[0].Line2 : '',
  Town: data.Addresses ? data.Addresses[0].Town : '',
  Postcode: data.Addresses ? data.Addresses[0].Postcode : '',
  DisclosureServiceType: +data.DisclosureServiceType,
  DisclosureFee: +data.DisclosureFee,
  TelNumber: data.TelNumber,
  EmailAddress: data.EmailAddress,
  UserType: data.UserType ? data.UserType : UserTypes.UNKNOWN
})

const PersonalDetails = ({ id, isEditing }) => {
  const [loaded, setLoaded] = useState(false)
  const [titles, setTitles] = useState([])
  const [addressList, setAddressList] = useState([])
  const [formData, setFormData] = useState({})
  const [ValidationErrors, setValidationErrors] = useState([])
  const [completed, setCompleted] = useState(false)
  const personalDetailsSchema = generatePersonalDetailsSchema()

  useEffect(() => {
    api.title.getAll().then(response => setTitles(formatTitles(response.data)))
    if (id) {
      api.newStart.getById(id).then(({ data }) => {
        setCompleted(data.Steps[0].Completed)
        setFormData(populateUserData(data))
        setLoaded(true)
      })
    } else {
      setLoaded(true)
    }
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    personalDetailsSchema.validate(formData, { abortEarly: false })
      .then(() =>
        adminUserService
          .personalDetails({ ...formData, Id: +id })
          .then(res => {
            if (completed) {
              navigate(`/admin/new-start/${res.data.Id}/overview`)
            } else {
              navigate(`/admin/new-start/${res.data.Id}/position-details`)
            }
          })
          .catch(serverError => {
            const is400Status = serverError.response.status === 400
            const alreadyExists = serverError.response.data.includes('New Start already exists.')
            const invalidNiNo = serverError.response.data.includes('The national insurance number is invalid.')
            const invalidPostcode = serverError.response.data.includes('Address postcode is invalid.')

            if(is400Status) {
              var apiValidationErrors = [];

              if(alreadyExists){
                apiValidationErrors.push(
                  {
                    path: 'EmailAddress',
                    message: 'This email address already exists'
                  }
                )
              }

              if(invalidNiNo){
                apiValidationErrors.push(
                  {
                    path: 'NINumber',
                    message: 'The national insurance number is invalid'
                  }
                )
              }

              if(invalidPostcode){
                apiValidationErrors.push(
                  {
                    path: 'Postcode',
                    message: 'The address postcode is invalid'
                  }
                )
              }
            }

            setValidationErrors(apiValidationErrors)
          })
      )
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const handleChange = e => {
    const {
      name,
      value,
      dataset: { format }
    } = e.target

    setFormData({ ...formData, [name]: valueFormat(format, value) })
  }

  const handlePostcodeLookup = e => {
    setAddressList([])
    personalDetailsSchema.validateAt("Postcode", formData).then(() =>
      api.addressLookup(formData.Postcode).then(response => {
        setFormData({ ...formData, Line1: '', Line2: '', Town: '' })
        if(response.data.length >= 1){
          setAddressList(response.data)
        } else {
          setValidationErrors([
            {
              path: 'Postcode',
              message: 'No addresses found'
            }
          ])
        }
      }) 
    )
    .catch(error => {
      setValidationErrors([
        {
          path: 'Postcode',
          message: error.message
        }
      ])
      focusOnError()
    });

    e.preventDefault()
  }

  const handleAddressSelect = e => {
    const selectedAddress = addressList[e.target.value]
    const { Line1, Line2, Town, Postcode } = selectedAddress
    setFormData({ ...formData, Line1, Line2, Town, Postcode })
    setAddressList([])
  }


  return loaded ? (
    <Layout>
      {!completed && <Stepper steps={stepperSteps.newStart(id)} currentStep={1} />}

      <ContentWrap slim>
        <form onSubmit={handleSubmit}>
          <FormWrapper>
            <FormSelectInput
              name="TitleId"
              label="Title *"
              value={formData.TitleId}
              onChange={handleChange}
              util={width.w4Sm}
              data-format="int"
              error={getErrorForField('TitleId', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
            >
              <option />

              {titles.map(item => (
                <option key={item.Id} value={item.Id}>
                  {toTitleCase(item.Name)}
                </option>
              ))}
            </FormSelectInput>

            <FormTextInput
              name="Firstname"
              type="text"
              label="First name *"
              value={formData.Firstname}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('Firstname', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />

            <FormTextInput
              name="Surname"
              type="text"
              label="Surname *"
              value={formData.Surname}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('Surname', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />

            <FormTextInput
              name="ReferralCode"
              type="number"
              label="Referral Code"
              value={formData.ReferralCode}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('ReferalCode', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />

            <FormTextInput
              name="DateOfBirth"
              type="date"
              label="Date of birth *"
              value={formData.DateOfBirth}
              onChange={handleChange}
              util={width.w4Sm}
              placeholder="yyyy-mm-dd"
              pattern="\d{4}-\d{2}-\d{2}"
              error={getErrorForField('DateOfBirth', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
              max={moment.utc().format('YYYY-MM-DD')}
            />

            <FormTextInput
              name="NINumber"
              type="text"
              label="NI number *"
              value={formData.NINumber}
              onChange={handleChange}
              util={width.w4Sm}
              error={getErrorForField('NINumber', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />

            <FormTextInput
              name="PassportExpiry"
              type="date"
              label="Passport Expiry Date"
              value={formData.PassportExpiry}
              onChange={handleChange}
              util={width.w4Sm}
              placeholder="yyyy-mm-dd"
              pattern="\d{4}-\d{2}-\d{2}"
              autoComplete="off"
              error={getErrorForField('PassportExpiry', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              min={moment.utc().format('YYYY-MM-DD')}
            />

            <FormRadioGroup
              hint={
                isEditing
                  ? 'This field is not editable'
                  : "Choosing 'Apprentice' will add an extra step for the employee"
              }
              options={[
                {
                  label: 'Normal',
                  value: 1,
                  checked: formData.UserType === UserTypes.NORMAL || ''
                },
                {
                  label: 'Apprentice',
                  value: 2,
                  checked: formData.UserType === UserTypes.APPRENTICE || ''
                }
              ]}
              id="UserType"
              name="UserType"
              label="Type of employee *"
              onChange={handleChange}
              data-format="int"
              error={getErrorForField('UserType', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              disabled={isEditing}
            />

            <FormDivider />

            <Text h3 util={margin.b4}>
              Address details
            </Text>

            <Grid row>
              <Grid colSm={6}>
                <Form label>Postcode *</Form>
                <Grid row>
                  <Grid colXs={8}>
                    <FormTextInput
                      name="Postcode"
                      type="text"
                      value={formData.Postcode}
                      onChange={handleChange}
                      error={getErrorForField('Postcode', ValidationErrors)}
                      onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid colXs={4} util={padding.a0Sm}>
                    <Button block onClick={handlePostcodeLookup}>
                      Look up
                    </Button>
                  </Grid>
                </Grid>

                {addressList.length > 0 && (
                  <FormSelectInput
                    name="AddressSelect"
                    label="Select Address"
                    onChange={handleAddressSelect}
                    value={formData.Line1 || ''}
                  >
                    <option />

                    {addressList.map((item, i) => (
                      <option key={item.Line1} value={i}>
                        {item.Line1}
                      </option>
                    ))}
                  </FormSelectInput>
                )}
              </Grid>
            </Grid>

            <FormTextInput
              name="Line1"
              type="text"
              label="Address line 1 *"
              value={formData.Line1 || ''}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('Line1', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />

            <FormTextInput
              name="Line2"
              type="text"
              label="Address line 2"
              value={formData.Line2 || ''}
              onChange={handleChange}
              util={width.w6Sm}
              autoComplete="off"
            />

            <FormTextInput
              name="Town"
              type="text"
              label="Town *"
              value={formData.Town || ''}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('Town', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />

            <FormDivider />

            <Text h3 util={margin.b4}>
              Disclosure
            </Text>

            <FormSelectInput
              name="DisclosureServiceType"
              label="Disclosure Service *"
              value={formData.DisclosureServiceType || ''}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('DisclosureServiceType', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
            >
              <option>Select...</option>
              <option value="2">Disclosure Scotland</option>
              <option value="1">Disclosure {'&'} Barring Service (rest of UK)</option>
            </FormSelectInput>

            <FormSelectInput
              name="DisclosureFee"
              label="Disclosure Fee *"
              value={formData.DisclosureFee !== undefined ? formData.DisclosureFee : ''}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('DisclosureFee', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
            >
              <option>Select...</option>
              <option value="25">£25</option>
              <option value="21.5">£21.50</option>
              <option value="0">£0</option>
            </FormSelectInput>

            <FormDivider />

            <Text h3 util={margin.b4}>
              Contact details
            </Text>

            <FormTextInput
              name="TelNumber"
              type="tel"
              label="Telephone number *"
              hint="Country code is mandatory, e.g. +44"
              placeholder="+44 7890 *** ***"
              value={formData.TelNumber}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('TelNumber', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />

            <FormTextInput
              name="EmailAddress"
              type="text"
              label="Email *"
              value={formData.EmailAddress}
              onChange={handleChange}
              util={width.w6Sm}
              error={getErrorForField('EmailAddress', ValidationErrors)}
              onBlur={e => handleBlur(e, personalDetailsSchema, formData, setValidationErrors)}
              autoComplete="off"
            />
          </FormWrapper>

          <Button success type="submit" util={position.floatRight}>
            {completed ? 'Update details' : 'Next step'}
          </Button>
        </form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

PersonalDetails.propTypes = {
  id: PropTypes.string,
  isEditing: PropTypes.bool
}

export default PersonalDetails
